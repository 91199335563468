@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");
html {
  background-color: rgb(235, 231, 241);
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  padding: 0;
  margin: 0;
  font-size: 1.3rem;
  background-color: rgb(235, 231, 241);
  font-family: "Kaushan Script", cursive;
}
header {
  margin-top: 10px;
  & a {
    display: flex;
    justify-content: center;
  }
}
h2 {
  font-size: 3rem;
  padding-right: 5px;
}
h1 {
  margin-top: 10px;
  color: rgb(235, 116, 136);
  font-size: 4rem;
}
#cartp {
  width: 50px;
  height: auto;
  margin-left: 30%;
}
#back h3 {
  margin: 0;
  padding: 0;
}
.navbar {
  background-color: rgb(235, 231, 241);
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.auth a {
  color: black;
  font-weight: 700;
  padding: 1rem;
}
.nav {
  display: flex;
  inline-size: auto;
  justify-content: space-evenly;
  list-style: none;
}
.nav li {
  font-size: 2rem;
  text-align: center;
  & i {
    transition: all 1s ease;
    opacity: 0.7;
  }
}

.search {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.material-icons {
  background-color: rgb(255, 125, 147);
  padding-inline: 5px 10px;
}
.cake-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  align-items: end;
  justify-items: center;
  margin-top: 3rem;
  padding-bottom: 2rem;
}
img {
  background-position: center;
  background-size: cover;
  height: 200px;
  width: 200px;
}
.desc {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  background-color: white;
  list-style: none;
  padding: 1rem;
  justify-content: space-between;
}
.cart-item {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  margin-bottom: 2rem;
}
@media (hover: hover) and (pointer: fine) {
  nav li i:hover {
    transform: translateY(-1rem);
  }
}

@media only screen and (max-width: 500px) {
  .cart-item {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    margin-bottom: 2rem;
  }
}
#cart {
  display: flex;
  place-content: center;
  padding-bottom: 2rem;
}
.total {
  align-self: flex-end;
}
.user {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem;
}
.user button {
  margin-left: 2rem;
  max-width: fit-content;
  font-weight: 600;
}
.formErr {
  color: "red";
  font-weight: 600;
}
.alert {
  color: yellow;
  font-weight: 600;
}
