body {
  --primary: #631d76;
  --secondary: #9e4770;
  --white: #fbfbfb;
  --dark: #2e2532;
  --black: #201a23;
}

.product-card figure {
  text-align: center;
  padding: 5px;
}

.navbar ul.social {
  display: flex;
}
.navbar ul.social li {
  margin-right: 20px;
  text-decoration: none;
}
.navbar ul.social li a {
  color: var(--white);
}

.banner {
  margin: 50px;
}

.columns.products {
  flex-wrap: wrap;
}

.card-footer-item i {
  margin-right: 10px;
}

.navbar-bottom.navbar {
  align-items: center;
}

.navbar-top.navbar {
  background-color: var(--primary);
}

.navbar-buttons {
  display: flex;
}

.navbar-top .container.navbar-container,
.navbar-bottom .container.navbar-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 5%;
  align-items: center;
}

#cart h3 {
  margin-top: 50px;
}

#cart .cart-item {
  margin-bottom: 10px;
  padding: 20px;
}

.product-card .card-footer {
  justify-content: center;
}
.product-card .card-footer form {
  display: block;
  width: 100%;
}

div.container {
  margin: 40px 10%;
}

.control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table {
  width: 100%;
}

.table th:first-of-type {
  width: 50%;
}
.table td:first-of-type {
  width: 50%;
}

.table td {
  vertical-align: middle;
}

.product-card .card-footer button {
  border: none;
  width: 100%;
  height: 50px;
}

.product-card .card-footer button i {
  margin-right: 10px;
}

.message.is-success {
  animation: slideIn 5s ease forwards;
}
@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }
  30% {
    transform: translateX(0);
  }
  70% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

#cart .cart-item h3 {
  margin: 0;
}

.cart-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.quantity {
  position: relative;
}

.quantity:before {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 5px;
  bottom: 0;
  display: flex;
  align-items: center;
  color: gray;
}
.quantity button {
  border: none;
  background-color: transparent;
  font-size: 1.5rem;
  margin: 0 10px;
}
.quantity button:hover {
  cursor: pointer;
}
.cart-right .field {
  margin-bottom: 0 !important;
}

.price {
  margin: 0 20px;
}

.cart-right {
  display: flex;
  align-items: center;
}
